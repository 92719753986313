import { useEffect } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

function App() {
  useEffect(() => {
    const map = new maplibregl.Map({
      container: "map",
      style: `https://api.maptiler.com/maps/b95be0d4-5437-4b33-bb95-94b8390687ca/style.json?key=8q3RwzewL60866yi92gW`,
      zoom: 9,
      center: { lat: -6.36088, lng: 106.85256 },
      hash: true,
      maxZoom: 21.9,
    });
    map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        showUserHeading: true,
        showAccuracyCircle: true,
        trackUserLocation: false,
      }),
    );
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: "0",
        left: "0",
      }}
      id="map"
    />
  );
}

export default App;
